.container {
    width: 70vw;
    min-width: 60em;
    height: 90vh;
    margin: 1.6em auto;
}

.containerGps {
    width: 100%;
    height: 100%;
    /* margin: 3em auto; */
  }

.headerPage {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 80px;
  }

.groupChild2 {
    position: relative;
    /* margin-top: 11px; */
    height: 95px;
    width: 350px;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
}