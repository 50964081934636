.sidebar-logo-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
}

.sidebar-header-container {
  margin-left: auto;
  padding: 0;
  height: 10vh;
  background: white;
  border-bottom: 1px solid #dedede;
}

.sidebar-header-container h3 {
    margin-top: 1vh;
}

.sidebar-header-title {
    display: flex;
    align-items: center;
    /* align-self: center; */
    height: 100%;
}


.sidebar-header-edge {
  display: flex;
  align-items: center;
}

/* .ant-menu-inline-collapsed >.ant-menu-item {
  padding-inline: calc(50% - 12px);
} */

.ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item {
  width: 70px;
}

.header-avatar {
  background-color: var(--lightDGreyColor);
  padding: 20%;
}

.ant-menu-inline .ant-menu-item {
  margin-inline: 0;
  margin-block: 0;
  width: 100%;
  height: 48px;
  border-radius: 0;
}

.ant-menu {
  background-color: #f0f8ff;
}